<template>
  <div
    v-if="
      blok.title?.length || blok.iconName?.length || blok.attributes?.length
    "
    class="facility-attribute"
  >
    <SvgIcon :name="blok.iconName" />
    <div class="facility-attribute-content">
      <h2 v-if="blok.title" class="size-m">{{ blok.title }}</h2>
      <h3 v-if="blok.editorialTitle" class="size-m">
        {{ blok.editorialTitle }}
      </h3>
      <div v-if="text?.length" class="size-s" v-html="text"></div>
      <ul v-if="blok.attributes.length" class="size-s">
        <li v-for="(item, index) in blok.attributes" :key="index">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
});

const { text } = useRichText(props.blok);
</script>

<style lang="scss" scoped>
.facility-attribute {
  display: flex;
  padding: 2rem 2.5rem 2.75rem 2rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 4rem;
  border-radius: $radius-l;
  width: 18rem;
  @include pair-1;
  @include for-desktop-up {
    width: auto;
    grid-column: span 6;
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }

  &-content :deep(ul) {
    margin-left: 1.5rem;
    list-style: disc;
  }
}
.alternate .facility-attribute {
  @include pair-7;
}
</style>
